
import context from '@cloudfun/core'
import { defineComponent, ref } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  setup () {
    const searchDropdown = ref(false)

    const showSearchDropdown = () => {
      searchDropdown.value = true
    }

    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      isGuest: !context.current?.user.Id
    }
  },
  computed: {
    ...mapGetters(['user']),
    homePath () {
      const path = this.$route.path
      if (path.startsWith('/simple-menu')) return '/simple-menu'
      if (path.startsWith('/top-menu')) return '/top-menu'
      return '/'
    }
  },
  methods: {
    logout () {
      if (!this.$user.Id) {
        this.$router.push('/login')
        return
      }
      this.$model.dispatch('logout').finally(() => {
        this.$model.user = this.$model.guest
        this.$router.push('/login')
      })
    }
  },
  watch: {
    user (current) {
      this.isGuest = !current.Id
    }
  }
})
